<script>
    export let link = '';
    import { texts } from '../data.js';
    export let lang = '';
    export let version;
    export let platform = '';
    let breakoutRoomImg = '';
    let box3 = '';
    $: if (platform === 'zoom') {
        breakoutRoomImg = 'breakoutroom.png';
        box3 = 'zoom_box3_zoom';
    } else if (platform === 'teams') {
        breakoutRoomImg = 'breakoutroom-teams.png';
        box3 = 'zoom_box3_teams';
    }
</script>

<div class="container">
    <h1 class="header" id="powitanietytul">{texts[lang].zoom_title}</h1>

    <div class={version === 3 ? 'xmas-box' : 'box'} id="powitanie">
        {@html texts[lang].zoom_box1}
    </div>

    <br />
    <slot />
    <br />
    <div class={version === 3 ? 'xmas-minibox' : 'minibox'} id="rundblackbox">
        <h3>{texts[lang].zoom_box2_left_title}</h3>
        <br />
        {texts[lang].zoom_box2_left}
        <br /><br />
        <img src="./img/crowdpurr1.jpg" height="350px" alt="crowdpurr_1" />
    </div>
    <div class={version === 3 ? 'xmas-minibox' : 'minibox'} id="rundblackbox">
        <h3>{texts[lang].zoom_box2_right_title}</h3>
        <br />
        {texts[lang].zoom_box2_right}
        <br /><br /><br />
        <img src="./img/crowdpurr2.jpg" height="350px" alt="crowdpurr_2" />
    </div>
    <div class={version === 3 ? 'xmas-box' : 'box'} id="rundblackbox">
        <h3>{texts[lang].zoom_box3_title}</h3>

        <div class="flexbox">
            {#if platform === 'zoom'}
                <div class="flexitem">
                    {texts[lang].zoom_browser}
                    <img
                        src="./img/breakout-window-{lang}.png"
                        class="img__instruction"
                        alt="where_team2"
                    />
                </div>
            {/if}
            <div class="flexitem">
                {texts[lang][box3]}
                <img
                    src="./img/{breakoutRoomImg}"
                    class="img__instruction"
                    alt="where_team"
                />
            </div>
        </div>
    </div>
    <br />
    <a href={link} target="_blank"
        ><div class="btn">{texts[lang].zoom_quiz_button}</div></a
    >
</div>
