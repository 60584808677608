<script>
    import { texts } from '../data.js';
    export let lang = '';
</script>

<div class="container">
    <br />
    <div class="clr" />
    <div class="box" id="rundaopis">
        {texts[lang].characters1}
        <br />
        {texts[lang].characters2}
        <br />
        {texts[lang].characters3}
    </div>

    <slot />
    <div class="box">
        <table style="width: 100%">
            <thead>
                <tr>
                    <th>{texts[lang].characters_table_header.author}</th>
                    <th>{texts[lang].characters_table_header.name}</th>
                    <th>{texts[lang].characters_table_header.role}</th>
                </tr>
            </thead>
            <tbody class="nsort">
                {#each texts[lang].characters_table as char}
                    <tr>
                        <td>{char.author}</td>
                        <td>{char.name}</td>
                        <td>{char.role}</td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
</div>
