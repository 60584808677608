<script>
    import { texts } from '../data.js';
    export let lang = '';
</script>

<div class="container">
    <br />
    <div class="clr" />
    <div class="xmas-box" id="rundaopis">
        {texts[lang].movies1}
        <br />
        {texts[lang].movies2}
    </div>

    <slot />
    <div class="xmas-box">
        <table style="width: 100%">
            <thead>
                <tr>
                    <!-- <th>{texts[lang].movies_table_header.pic}</th> -->
                    <th>{texts[lang].movies_table_header.name}</th>
                    <th>{texts[lang].movies_table_header.title}</th>
                </tr>
            </thead>
            <tbody class="nsort">
                {#each texts[lang].movies_table as char}
                    <tr>
                        <td class="flex"
                            >
                            {char.name}
                            <img
                                class="gif"
                                src="./img/christmas_movies/{char.pic}"
                                alt=""
                            />
                            </td >
                        <!-- <td>{char.name}</td> -->
                        <td>{char.title}</td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
</div>
