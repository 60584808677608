exports.texts = {
    pl: {
        lost_link_part1:
            '"Gdzieś mi zniknął quiz i nie mogę odpowiadać na pytania!" - jeżeli masz taki problem, kliknij',
        lost_link_part2: ' tutaj.',
        wrong_gameId: 'Błędny adres gry, skontaktuj sie z trenerem misiu',
        pictures_description:
            'W tej rundzie Waszym zadaniem jest znalezienie dziesięciu różnic między tymi dwoma obrazkami. Najlepiej wszystkich, więc współpracujcie skutecznie! Pytania będą dotyczyć znalezionych (lub nie!) przez Was różnic, a nie będzie czasu na szukanie ich w trakcie trwania quizu!',
        round: 'Runda',
        zoom_title: 'Witajcie!',
        zoom_box1:
            'Jesteście w wydzielonej przestrzeni dla waszej ekipy: to czas dla was, żebyście ze sobą pogadali i sprawdzili, czy wszystko działa: mikrofony, kamery, dobry humor i kawa. Jeżeli macie problemy sprzętowe lub technologiczne, <a href="https://mindlab.pl/pomoc-techniczna" target="_blank">tutaj</a> znajdziecie odpowiedzi na najczęściej pojawiające się pytania. Następnie - zapraszamy poniżej!',
        zoom_box2_left_title: 'Co mam zrobić po uruchomieniu quizu? ',
        zoom_box2_left:
            'Najpierw - wpisz imię, pod jakim chcesz występować w quizie. Byłoby super, gdyby było takie samo jak na zoomie!',
        zoom_box2_right_title: 'A potem?',
        zoom_box2_right: 'Wybierz z listy drużynę, do której należysz!',
        zoom_box3_title: 'Skąd mam wiedzieć, w której drużynie jestem?',
        zoom_box3_zoom:
            'W aplikacji, nazwa Twojej drużyny znajduje się też w tytule okna komunikatora zoom - nazwa wyświetli się, gdy najedziesz na pasek narzędzi zooma.',
        zoom_box3_teams:
            'Nazwa Twojej drużyny znajduje się w tytule okna komunikatora teams - nazwa wyświetli się, gdy najedziesz na pasek narzędzi teams.',
        zoom_quiz_button: 'Uruchom quiz!',
        zoom_browser:
            'Jeśli używasz wersji w przeglądarce, zapamiętaj nazwę pokoju z popupu z zaproszeniem.',
        characters1:
            'Ta część zabawy dotyczy znanych literackich postaci i ich autorów.',
        characters2:
            'W ramach przygotowania musicie zidentyfikować jak najwięcej poprawnych zestawień rola - autor - postać.',
        characters3:
            "Na przykład - słynnym wiedźminem u Sapkowskiego był Geralt, a kapitanem u Verne'a był Nemo.",
        characters_table_header: {
            author: 'Autor',
            name: 'Imię lub nazwisko postaci',
            role: 'Rola postaci',
        },
        characters_table: [
            { author: 'Christie', name: 'Jane Bennet', role: 'namiestnik' },
            { author: 'Conan-Doyle', name: 'Yarpen', role: 'gospodyni' },
            { author: 'Defoe', name: 'Ralph', role: 'panna' },
            { author: 'Eco', name: 'Swann', role: 'detektyw' },
            { author: 'Golding', name: 'Hudson', role: 'nastolatka' },
            { author: 'Heller', name: 'Dolittle', role: 'kapitan' },
            { author: 'Lofting', name: 'Adso', role: 'babcia' },
            { author: 'Mickiewicz', name: 'Piętaszek', role: 'dama' },
            { author: 'Austen', name: 'Denethor', role: 'rozbitek' },
            { author: 'Pratchett', name: 'Yossarian', role: 'górnik' },
            { author: 'Sapkowski', name: 'Poirot', role: 'służący' },
            { author: 'Meyer', name: 'Telimena', role: 'doktor' },
            { author: 'Tolkien', name: 'Weatherwax', role: 'nowicjusz' },
        ],
        trainer_send_button: 'Wyślij',
        trainer_round0: 'Przygotowanie',
        trainer_round1: ['Zwierzątka', 'Wykreślanka', 'Wykreślanka'],
        trainer_round2: ['Obrazki', 'Obrazki', 'Obrazki'],
        trainer_round3: ['Postacie', 'Seriale', 'Filmy'],
        trainer_message_label: 'Wiadomość do graczy',
        trainer_servermsg_label: 'Wiadomości z serwera',
        trainer_reset_game: 'Reset gry',
        trainer_start_timer: 'Uruchom minutnik',
        page_title_prefix: 'Dociekando - ',
        page_title_suffix: {
            v1: [
                'Przygotowanie',
                'Zwierzeta w jezyku',
                'Obrazki',
                'Postacie Literackie',
            ],
            v2: ['Przygotowanie', 'Wykreślanka', 'Obrazki', 'Seriale'],
            v3: ['Przygotowanie', 'Wykreślanka', 'Obrazki', 'Filmy'],
        },
        word_puzzle_1:
            'W tej rundzie waszym zadaniem jest znaleźć 30 ukrytych słów! Im więcej znajdziecie, tym łatwiej będzie wam odpowiedzieć na pytania w quizie, więc lepiej się pospieszcie!',
        word_puzzle_2:
            'Słowa mogą pojawiać się pionowo lub poziomo, i mogą na siebie nachodzić. Aby wybrać znalezione słowo, po prostu kliknij jego pierwszą i ostatnią literę.',
        word_puzzle_3:
            'Pamiętaj, że tylko Ty widzisz swój wybór, więc podziel się swoimi spostrzeżeniami z resztą zespołu!',
        series1: 'Ta część zabawy dotyczy znanych serialowych postaci.',
        series2:
            'W ramach przygotowania musicie zidentyfikować jak najwięcej poprawnych zestawień wizerunek - postać - serial. Na przykład kapitanem w "Stawce większej niż życie" był Kloss, a seryjnym mordercą w "Dexterze" - Dexter.',
        series_table_header: {
            pic: 'Wizerunek',
            name: 'Imię lub nazwisko postaci',
            title: 'Serial  ',
        },
        series_table: [
            {
                pic: 'janice.gif',
                name: 'Al Bundy',
                title: 'Miodowe Lata',
            },
            {
                pic: 'Czarnobyl-boris-shcherbina-valery-legasov.gif',
                name: 'Gregory House',
                title: 'Dom z Papieru',
            },
            { pic: 'norek.gif', name: 'Rust Cohle', title: 'Sherlock BBC' },
            {
                pic: 'Hawkeye.gif',
                name: 'Dale Cooper',
                title: 'Breaking Bad',
            },
            { pic: 'walter white.gif', name: 'Ricky', title: 'Dr House' },
            {
                pic: 'ricky_chlopakizbarakow.gif',
                name: 'Walter White',
                title: 'Świat według Bundych',
            },
            {
                pic: 'mcnulty.gif',
                name: '"Hawkeye" Pierce',
                title: 'True Detective',
            },
            { pic: 'scullyfbi.gif', name: 'Jimmy McNulty', title: 'Friends' },
            {
                pic: 'lestrade.gif',
                name: 'Eleven',
                title: 'Czterej pancerni i pies',
            },
            {
                pic: 'jedynastka.gif',
                name: 'Diana Scully',
                title: 'Czterdziestolatek',
            },
            { pic: 'marusia.gif', name: 'Profesor', title: 'Stranger Things' },
            {
                pic: 'doctorhouse.gif',
                name: 'Oberyn Martell',
                title: 'Miasteczko Twin Peaks',
            },
            { pic: 'albundy.gif', name: 'Marusia', title: 'Gra o tron' },
            {
                pic: 'kobieta_pracujaca.gif',
                name: 'Greg Lestrade',
                title: 'M.A.S.H.',
            },
            {
                pic: 'oberyn_martell.gif',
                name: 'Janice',
                title: 'Chłopaki z baraków',
            },
            {
                pic: 'le-casa-de-papel.gif',
                name: 'Tadeusz Norek',
                title: 'Z Archiwum X',
            },
            {
                pic: 'rusty-cohle_true-detective.gif',
                name: 'Valery Legasow',
                title: 'Prawo ulicy',
            },
            {
                pic: 'dale_cooper_twinpeaks.gif',
                name: 'Kobieta pracująca',
                title: 'Czarnobyl',
            },
        ],
        movies1:
            'Ta część zabawy dotyczy filmów z motywami świątecznymi. Zobaczymy jak sobie poradzicie z takim zadaniem, uprzedzamy, że "Kevin sam w domu" nie wystarczy 😊 ',
        movies2:
            'W ramach przygotowania musicie zidentyfikować jak najwięcej poprawnych zestawień postać - film. Na przykład kapitanem w "Stawce większej niż życie" był Kloss, a seryjnym mordercą w "Dexterze" - Dexter.',
        movies_table_header: {
            pic: 'Wizerunek',
            name: 'Imię, nazwisko lub pseudonim postaci',
            title: 'Film',
        },
        movies_table: [
            {
                pic: 'smierc-wiedzmikolaj.gif',
                name: 'Śmierć',
                title: 'Cud na 34 ulicy',
            },
            {
                pic: 'williesoke-zlymikolaj.gif',
                name: 'Willie T.Soke',
                title: 'Dziennik Bridget Jones',
            },
            {
                pic: 'clarkgriswold-witajswietymikolaju.gif',
                name: 'Clark Griswold',
                title: 'Elf',
            },
            {
                pic: 'mikolaj-kronikaswiateczna.gif',
                name: 'Mikołaj',
                title: 'Gremliny rozrabiają',
            },
            {
                pic: 'melchior-listydom.jpg',
                name: 'Melchior',
                title: 'Kevin sam w domu',
            },
            {
                pic: 'krisskringle-cudna34ulicy.gif',
                name: 'Kriss Kringle',
                title: 'Jak Grinch ukradł święta',
            },
            {
                pic: 'johnmcclane-szklanapulapka.gif',
                name: 'JOhn McClane',
                title: 'Kronika Świąteczna',
            },
            {
                pic: 'jackskellington-miasteczkohalloween.gif',
                name: 'Jack Skellington',
                title: 'Listy do M',
            },
            {
                pic: 'harrylime-kevinsamwdomu.gif',
                name: 'Harry Lime',
                title: 'Miasteczko Halloween',
            },
            {
                pic: 'grinch-jakgrinchukradlswieta.gif',
                name: 'Grinch',
                title: 'Szklana Pułapka',
            },
            {
                pic: 'gizmo-gremlinyrozrabiaja.gif',
                name: 'Gizmo',
                title: 'To właśnie miłość',
            },
            {
                pic: 'frankcross-wigilijnyshow.gif',
                name: 'Frank Cross',
                title: 'Wiedźmikołaj',
            },

            {
                pic: 'david-totylkomilosc.gif',
                name: 'David (premier)',
                title: 'Wigilijny Show',
            },
            {
                pic: 'darcy-dziennikbridgetjones.gif',
                name: 'Mark Darcy',
                title: 'Witaj Święty Mikołaju',
            },
            {
                pic: 'buddy-elf.gif',
                name: 'Buddy',
                title: 'Zły Mikołaj',
            },
        ],
    },
    eng: {
        lost_link_part1:
            '"I lost my Quiz somewhere and I can\'t answer the questions!" - if this have happened, please click',
        lost_link_part2: ' here.',
        wrong_gameId: 'Wrong gameId, inform your facilitator please.',
        pictures_description:
            'In this round your objective is to find up to ten differences between those two pictures. The more the better, so cooperate to the best of your abilities! The questions will concern the differences you have found (or not) and you will have no time to look for them during the quiz!',
        round: 'Round',
        zoom_title: 'Hello there!',
        zoom_box1:
            'You are in a space dedicated for your team: you can chat with each other and make sure that your microphones, cameras, good humour and coffee are all ready. If you have equipment or technical issues, you can find answers to the most common questions <a href="https://mindlab.pl/pomoc-techniczna" target="_blank" >here,</a> as well. Once you\'re ready - you can head down below!',
        zoom_box2_left_title: 'What should I do after starting the quiz?',
        zoom_box2_left:
            'First - enter the name you want to use in the quiz. It would be great if it matched your zoom name!',
        zoom_box2_right_title: 'And then?',
        zoom_box2_right: 'From the drop-down list select your team.',
        zoom_box3_title: "How do I know which team I'm in?",
        zoom_box3_zoom:
            'In zoom app, you can find also your team name at the top of the zoom windows - the name should appear when you hover over the toolbar.',
        zoom_box3_teams:
            'You can find your team name at the top of the teams window - the name should appear when you hover over the toolbar.',
        zoom_browser:
            'When using the browser version, you need to pay attention to the invitation popup!',
        zoom_quiz_button: 'Start the quiz!',
        characters1:
            'In this part of the game you will be dealing with well-known literary characters and their authors.',
        characters2:
            'As part of the preparation you have to recognize as many correct combinations of the role - author - character as possible.',
        characters3:
            "For example - the famous witcher in Sapkowski's books was Geralt and the captain in Verne's book was Nemo.",
        characters_table_header: {
            author: 'Author',
            name: 'Character name',
            role: 'Character role',
        },
        word_puzzle_1:
            'In this round, your task is to find 32 hidden words! The more you find, the easier it will be for you to answer the questions in the quiz, so you better hurry! ',
        word_puzzle_2:
            'Words may appear vertically, horizontally and diagonally. To select a word found, just click on its first and last letter.',
        word_puzzle_3:
            'Remember that only you can see your selection, so please share your findings with the rest of your team!',
        characters_table: [
            { author: 'Christie', name: 'Jane Bennet', role: 'steward' },
            { author: 'Conan-Doyle', name: 'Yarpen', role: 'landlady' },
            { author: 'Defoe', name: 'Ralph', role: 'miss' },
            { author: 'Eco', name: 'Swann', role: 'detective' },
            { author: 'Golding', name: 'Hudson', role: 'teenager' },
            { author: 'Heller', name: 'Dolittle', role: 'captain' },
            { author: 'Lofting', name: 'Adso', role: 'granny' },
            { author: 'Mickiewicz', name: 'Friday', role: 'lady' },
            { author: 'Austen', name: 'Denethor', role: 'castaway' },
            { author: 'Pratchett', name: 'Yossarian', role: 'miner' },
            { author: 'Sapkowski', name: 'Poirot', role: 'servant' },
            { author: 'Meyer', name: 'Telimena', role: 'doctor' },
            { author: 'Tolkien', name: 'Weatherwax', role: 'novice' },
        ],
        trainer_send_button: 'Send',
        trainer_round0: 'Preparation',
        trainer_round1: ['WordPuzzle', 'WordPuzzle', 'WordPuzzle'],
        trainer_round2: ['Pictures', 'Pictures', 'Pictures'],
        trainer_round3: ['Characters', 'Characters', 'Movies'],
        trainer_message_label: 'Message to players',
        trainer_servermsg_label: 'Server messages',
        trainer_reset_game: 'Reset game',
        trainer_start_timer: 'Start timer',
        page_title_prefix: 'HiveMind - ',
        page_title_suffix: {
            v1: [
                'Preparations',
                'Word Puzzle',
                'Pictures',
                'Literary characters',
            ],
            v2: [
                'Preparations',
                'Word Puzzle',
                'Pictures',
                'Literary characters',
            ],
            v3: ['Preparations', 'Word Puzzle', 'Pictures', 'Movies'],
        },
        movies1:
            'This part of the game is all about movies. And Christmas. Or, maybe both? Yeah, those are the movies about Christmas 😊 ',
        movies2:
            "When working with your team, you need to recognize correct combinations of two elements: Movie title and Character's name. Try to find as many as you can! ",
        movies_table_header: {
            pic: 'Photo',
            name: "Character's name or nickname",
            title: 'Movie',
        },
        movies_table: [
            {
                pic: 'smierc-wiedzmikolaj.gif',
                name: 'Death',
                title: 'Miracle on 34th Street',
            },
            {
                pic: 'williesoke-zlymikolaj.gif',
                name: 'Willie T.Soke',
                title: "Bridget Jones's Diary",
            },
            {
                pic: 'clarkgriswold-witajswietymikolaju.gif',
                name: 'Clark Griswold',
                title: 'Elf',
            },
            {
                pic: 'mikolaj-kronikaswiateczna.gif',
                name: 'Santa',
                title: 'Gremlins',
            },
            {
                pic: 'melchior-listydom.jpg',
                name: 'Melchior',
                title: 'Home Alone',
            },
            {
                pic: 'krisskringle-cudna34ulicy.gif',
                name: 'Kriss Kringle',
                title: 'How the Grinch Stole Christmas',
            },
            {
                pic: 'johnmcclane-szklanapulapka.gif',
                name: 'John McClane',
                title: 'The Christmas Chronicles',
            },
            {
                pic: 'jackskellington-miasteczkohalloween.gif',
                name: 'Jack Skellington',
                title: 'Letters to Santa',
            },
            {
                pic: 'harrylime-kevinsamwdomu.gif',
                name: 'Harry Lime',
                title: 'Nightmare before Christmas',
            },
            {
                pic: 'grinch-jakgrinchukradlswieta.gif',
                name: 'Grinch',
                title: 'Die Hard',
            },
            {
                pic: 'gizmo-gremlinyrozrabiaja.gif',
                name: 'Gizmo',
                title: 'Love Actually',
            },
            {
                pic: 'frankcross-wigilijnyshow.gif',
                name: 'Frank Cross',
                title: 'Hogfather',
            },

            {
                pic: 'david-totylkomilosc.gif',
                name: 'David',
                title: 'Scrooged',
            },
            {
                pic: 'darcy-dziennikbridgetjones.gif',
                name: 'Mark Darcy',
                title: " National Lampoon's Christmas Vacation",
            },
            {
                pic: 'buddy-elf.gif',
                name: 'Buddy',
                title: 'Bad Santa',
            },
        ],
    },
};
exports.backgroundClasses = {
    v1: {
        pl: [
            'start-round',
            'animals-round',
            'pictures-round',
            'characters-round',
        ],
        eng: [
            'start-round',
            'wordpuzzle-round',
            'pictures-round',
            'characters-round',
        ],
    },
    v2: {
        pl: [
            'start-round',
            'wordpuzzle-round',
            'pictures-round',
            'series-round',
        ],
        eng: [
            'start-round',
            'wordpuzzle-round',
            'pictures-round',
            'characters-round',
        ],
    },
    v3: {
        pl: [
            'xmas-start-round',
            'xmas-wordpuzzle-round',
            'xmas-pictures-round',
            'xmas-movies-round',
        ],
        eng: [
            'xmas-start-round',
            'xmas-wordpuzzle-round',
            'xmas-pictures-round',
            'xmas-movies-round',
        ],
    },
};
