<script>
    export let eventData = { round: { roundTitle: { v1: {}, v2: {} } } };
    import { texts } from '../data.js';
    export let lang = '';
    let version;
    $: !eventData.version ? (version = 1) : (version = eventData.version);
</script>

<div class="hwers">
    <h2 class="header" id="nazwa">{eventData.title}</h2>
    <h2 class="header" id="data">{eventData.date}</h2>
<!-- </div>
<div class="hwers"> -->
    <h2 class="header" id="rundatytul">
        {#if lang}
            {eventData.round.roundTitle[`v${version}`][lang]}{/if}
    </h2>
    <h2 class="header" id="runda">
        {#if lang} {texts[lang].round} {/if}
        {eventData.round.roundNumber}
    </h2>
    <slot />

</div>
