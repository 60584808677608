<script>
    import { texts } from '../data.js';
    export let lang = '';
    export let version = 1;
</script>

<div class="container">
    <br />
    <div class="clr" />
    <div class={version === 3 ? 'xmas-box' : 'box'} id="rundaopis">
        {texts[lang].pictures_description}
    </div>

    <slot />
</div>
<div>
    <img
        class="imgleft"
        src="./img/r{version}-01.jpg"
        width="100%"
        alt="img_left"
    />
    <img
        class="imgright"
        src="./img/r{version}-02.jpg"
        width="100%"
        alt="img_right"
    />
</div>
<div class="clr" />
