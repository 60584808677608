<script>
    import { texts } from '../data.js';
    export let lang = '';
</script>

<div class="container">
    <br />
    <div class="clr" />
    <div class="box" id="rundaopis">
        {texts[lang].series1}
        <br />
        {texts[lang].series2}
    </div>

    <slot />
    <div class="box">
        <table style="width: 100%">
            <thead>
                <tr>
                    <th>{texts[lang].series_table_header.pic}</th>
                    <th>{texts[lang].series_table_header.name}</th>
                    <th>{texts[lang].series_table_header.title}</th>
                </tr>
            </thead>
            <tbody class="nsort">
                {#each texts[lang].series_table as char}
                    <tr>
                        <td
                            ><img
                                class="gif"
                                src="./img/{char.pic}"
                                alt=""
                            /></td
                        >
                        <td>{char.name}</td>
                        <td>{char.title}</td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
</div>
