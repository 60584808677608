<script>
    import io from 'socket.io-client';
    import Swal from 'sweetalert2';
    import ZoomTutorial from './ZoomTutorial.svelte';
    import WordPuzzle from './WordPuzzle.svelte';
    import Spinner from '../Utils/Spinner.svelte';
    import Series from './Series.svelte';
    import EventDetails from './EventDetails.svelte';
    import ChristMasMovies from './ChristMasMovies.svelte';
    import Animals from './Animals.svelte';
    import Characters from './Characters.svelte';
    import Pictures from './Pictures.svelte';
    import Timer from './Timer.svelte';
    import TrainerMessage from './TrainerMessage.svelte';
    import { texts, backgroundClasses } from '../data.js';
    export let trainerMessagesArr = [];
    export let tools;
    let timer = 0;
    let gameSocket = '';
    let checkSocket = '';
    export let eventData = {
        round: { roundTitle: {} },
        lang: 'pl',
    };
    const timeout = async (ms) => new Promise((res) => setTimeout(res, ms));
    export let lang = '';
    export let version = '';
    export let platform = '';

    function setBody() {
        let bgArr = backgroundClasses[`v${version}`][eventData.lang];
        bgArr.forEach((el) => {
            window.document.body.classList.remove(el);
        });
        let currBgr = bgArr[eventData.round.roundNumber];
        window.document.body.classList.add(currBgr);
    }
    initUser();

    async function initUser() {
        const connectionData = new URL(window.location);
        const gameLocation = connectionData.searchParams.get('game');
        if (!gameLocation) {
            await Swal.fire(texts[lang].wrong_gameId);
            return;
        }
        checkSocket = io({
            query: { game: gameLocation },
        });
        checkSocket.on('access allowed', () => {
            console.log(`access granted, conneting to ${gameLocation}`);
            gameLogin(gameLocation);
        });

        checkSocket.on('access denied', (data) => Swal.fire(data));
    }
    function gameLogin(game) {
        gameSocket = io(`/${game}`);
        gameSocket.on('welcome', async (data) => {
            // console.log('incoming data', data);
            eventData = data;
            eventData.lang === 'pl' || eventData.lang === 'eng'
                ? (lang = eventData.lang)
                : (lang = 'pl');
            eventData.lang = lang;
            eventData.version ? (version = eventData.version) : (version = 1);
            eventData.platform
                ? (platform = eventData.platform)
                : (platform = 'zoom');
            if (data.messages.length > 0) trainerMessagesArr = data.messages;
            
            setBody();
            console.log(eventData);
            await timeout(400)
            if (data.timer) {
                //console.log(data.timer);
                let currTime = Date.now();
                if (currTime < data.timer.start + data.timer.duration) {
                    const remainingTime = Math.floor(
                        (data.timer.start + data.timer.duration - currTime) /
                            1000
                    );
                    tools.startTimer(remainingTime, true);
                }
            }
        });
        gameSocket.on('round_change', (data) => {
            //console.log('rc', data);
            eventData.round = data;
            //console.log(lang);
            trainerMessagesArr = [];
            setBody();
        });
        gameSocket.on('trainer_message', (data) => {
            trainerMessagesArr = [data, ...trainerMessagesArr];
        });
        gameSocket.on('trainer_started_timer', (data) => {
            timer = data;
            tools.startTimer(data);
        });
    }
    let title = '';
    $: if (lang)
        title =
            texts[eventData.lang].page_title_prefix +
            texts[eventData.lang].page_title_suffix[`v${version}`][
                eventData.round.roundNumber
            ];
</script>

<svelte:head>
    <title>{title}</title>
</svelte:head>

{#if !lang}
    <Spinner />
{:else}
    <header>
        <img id="logoml" src="./img/logoML.jpg" width="150px" alt="ml" />
        <img
            id="logokl"
            src="./img/logoklient.jpg"
            width="150px"
            alt="client"
        />
    </header>
    <br />
    <div class="container">
        <EventDetails lang={eventData.lang} {eventData}
            ><Timer bind:tools /></EventDetails
        >

        <br />
    </div>
    {#if eventData.round.roundNumber === 1}
        {#if eventData.lang === 'pl'}
            {#if version === 2 || version === 3}
                <WordPuzzle lang={eventData.lang} {version}
                    >{#if trainerMessagesArr.length > 0}<TrainerMessage
                            messages={trainerMessagesArr}
                        />{/if}</WordPuzzle
                >
            {:else if version === 1}
                <Animals lang={eventData.lang}
                    >{#if trainerMessagesArr.length > 0}<TrainerMessage
                            messages={trainerMessagesArr}
                        />{/if}</Animals
                >
            {:else}
                <p>nie znam tej wersji, sprawdź plik EventsData!</p>
            {/if}
        {:else if eventData.lang === 'eng'}
            <WordPuzzle lang={eventData.lang} {version}
                >{#if trainerMessagesArr.length > 0}<TrainerMessage
                        messages={trainerMessagesArr}
                    />{/if}</WordPuzzle
            >
        {/if}
    {:else if eventData.round.roundNumber === 2}
        <Pictures lang={eventData.lang} {version}
            >{#if trainerMessagesArr.length > 0}<TrainerMessage
                    messages={trainerMessagesArr}
                />{/if}</Pictures
        >
    {:else if eventData.round.roundNumber === 3}
        {#if version === 2}
            <Series lang={eventData.lang}
                >{#if trainerMessagesArr.length > 0}<TrainerMessage
                        messages={trainerMessagesArr}
                    />{/if}</Series
            >
        {:else if version === 1}
            <Characters lang={eventData.lang}
                >{#if trainerMessagesArr.length > 0}<TrainerMessage
                        messages={trainerMessagesArr}
                    />{/if}</Characters
            >
        {:else if version === 3}
            <ChristMasMovies lang={eventData.lang}
                >{#if trainerMessagesArr.length > 0}<TrainerMessage
                        messages={trainerMessagesArr}
                    />{/if}</ChristMasMovies
            >
        {/if}
    {:else if eventData.round.roundNumber === 0}
        <ZoomTutorial
            {version}
            lang={eventData.lang}
            link={eventData.link}
            {platform}
            >{#if trainerMessagesArr.length > 0}<TrainerMessage
                    messages={trainerMessagesArr}
                />{/if}</ZoomTutorial
        >
    {/if}

    {#if eventData.round.roundNumber > 0}
        <div class="container">
            <div class={version === 3 ? 'xmas-box' : 'box'} id="missing_ling">
                {texts[eventData.lang].lost_link_part1}
                <a href={eventData.link} target="_blank"
                    >{texts[eventData.lang].lost_link_part2}</a
                >
            </div>
        </div>
    {/if}
{/if}
