<script>
    const minutesToSeconds = (minutes) => minutes * 60;
    const secondsToMinutes = (seconds) => Math.floor(seconds / 60);
    const padWithZeroes = (number) => number.toString().padStart(2, '0');
    let interval;

    function formatTime(timeInSeconds) {
        const minutes = secondsToMinutes(timeInSeconds);
        const remainingSeconds = timeInSeconds % 60;
        return `${padWithZeroes(minutes)}:${padWithZeroes(remainingSeconds)}`;
    }
    let timer = minutesToSeconds(0);
    let timerState = false;
    export const tools = {
        startTimer(duration, resume = false) {
            if (interval) {
                clearInterval(interval);
            }
            timerState = true;
            !resume ? (timer = minutesToSeconds(duration)) : (timer = duration);
            interval = setInterval(() => {
                if (timer === 0) {
                    timerState = false;
                    clearInterval(interval);
                    return;
                }
                timer -= 1;
            }, 1000);
        },
    };
</script>

{#if timerState}
    <h2 class="header" id="timer">
        <time>
            {formatTime(timer)}
        </time>
    </h2>
{/if}
