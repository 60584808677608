<script>
    import { texts } from '../data.js';
    export let lang = '';
    export let version;
    const puzzelSrc = {
        pl: [
            'placeholder, there is no wordPuzzle in this version PL',
            'https://puzzel.org/pl/wordseeker/embed?p=-M_tw-sOCQYRfLzAQ5US',
            'https://puzzel.org/pl/wordseeker/embed?p=-MlPKkDIx-BBg0c9J7HV',
        ],
        eng: [
            'https://puzzel.org/pl/wordseeker/embed?p=-MWhlJV-BpSOYFOysxLP',
            'placeholder, there is no wordpuzzle in this version eng',
            'https://puzzel.org/pl/wordseeker/embed?p=-Mmr4XbF9JEKUB8Y5OOQ',
        ],
    };
    $: console.log(version);
    // src={!version ? puzzelSrc[0] : puzzelSrc[version - 1]}
</script>

<div class="container">
    <div class="clr" />
    <div class={version === 3 ? 'xmas-box' : 'box'} id="rundaopis">
        {texts[lang].word_puzzle_1}<br />{texts[lang].word_puzzle_2}<br
        />{texts[lang].word_puzzle_3}
    </div>
    <slot />

    <div class={version === 3 ? 'xmas-box' : 'box'} id="rundblackbox">
        <div>
            <iframe
                title="WordPuzzle1.0"
                src={!version
                    ? puzzelSrc[lang][0]
                    : puzzelSrc[lang][version - 1]}
                width="970px"
                height="790px"
                frameborder="0"
            />
        </div>
    </div>
</div>
